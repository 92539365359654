import { formatDate } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Skeleton } from "@mui/material";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { useOpenShiftCount } from "@src/appV2/OpenShifts/api/useOpenShiftCount";
import { addMonths, differenceInDays, parseISO } from "date-fns";
import pluralize from "pluralize";
import { type ReactElement } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getIsExpiringSoon, getPotentialShiftsAndFacilitiesForRequirement } from "../../utils";
import { AccountsRequirementCard } from "../Components";
import {
  DEFAULT_DISTANCE_IN_MILES,
  PROFESSIONAL_REFERENCE_REQUIREMENT_ID,
  REQUIREMENT_STATUS_DATA,
} from "../constants";
import { useGetDocumentDetailsRoute } from "../hooks/useGetDocumentDetailsRoute";
import { DocumentRequirementType, type HcpRequirement } from "../types";

interface AccountsRequirementProps {
  requirement: HcpRequirement;
  requirementType: DocumentRequirementType;
}

export function AccountsRequirement(props: AccountsRequirementProps): ReactElement {
  const { requirement, requirementType } = props;
  const history = useHistory();
  const location = useLocation();
  const { name, expiry: expirationDateOfRequirement, reqId: requirementId } = requirement;
  const { data: agent } = useAgentProfile();

  const { actionIsRequired, icon } = REQUIREMENT_STATUS_DATA[requirementType];

  const isProfessionalReferenceRequirementAndStatusIsNotCompleted =
    requirementId === PROFESSIONAL_REFERENCE_REQUIREMENT_ID &&
    requirementType !== DocumentRequirementType.COMPLETED;

  const isExpiringSoon = getIsExpiringSoon(requirement, requirementType);

  const link = useGetDocumentDetailsRoute({
    shouldRouteToProfessionalReference: isProfessionalReferenceRequirementAndStatusIsNotCompleted,
    requirementId,
    documentRequirementType: requirementType,
  });

  const {
    data: openShiftCountData,
    isLoading: isOpenShiftsCountLoading,
    isSuccess: isOpenShiftsCountSuccessful,
  } = useOpenShiftCount(
    {
      startDate: new Date(),
      endDate: addMonths(new Date(), 1),
      qualification: agent?.qualification,
      coordinates: agent?.geoLocation?.coordinates,
      specialities: {
        hasSedationExperience: agent?.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: agent?.specialities?.hasTrayAssemblyExperience ?? false,
      },
      distance: DEFAULT_DISTANCE_IN_MILES,
      tmz: agent?.tmz ?? "",
      isAgent: true,
    },
    {
      enabled:
        isDefined(agent) && requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES,
    }
  );

  const potentialShiftAndFacilityDetails = isOpenShiftsCountSuccessful
    ? getPotentialShiftsAndFacilitiesForRequirement({
        openShiftCountData,
        requirement,
      })
    : undefined;

  const potentialShiftsCount = potentialShiftAndFacilityDetails?.potentialShiftsCount ?? 0;
  const potentialFacilityCount = potentialShiftAndFacilityDetails?.potentialFacilitiesCount ?? 0;

  return (
    <AccountsRequirementCard
      title={name}
      actionIsRequired={actionIsRequired || isExpiringSoon}
      statusIcon={isExpiringSoon ? <ReportProblemIcon color="warning" /> : icon}
      onClick={() => {
        history.push(link, { returnLocation: location });
      }}
    >
      <>
        {isExpiringSoon &&
          isDefined(expirationDateOfRequirement) &&
          differenceInDays(parseISO(expirationDateOfRequirement), new Date()) > 0 && (
            <Text key="expiry-date-typography" variant="body2">
              {`Expires in ${pluralize(
                "day",
                differenceInDays(parseISO(expirationDateOfRequirement), new Date()),
                true
              )} (${formatDate(parseISO(expirationDateOfRequirement))})`}
            </Text>
          )}
        {requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES &&
          potentialShiftsCount > 0 &&
          potentialFacilityCount > 0 && (
            <Text key="potential-shift-count-typography" variant="body2">
              {`Access ${potentialShiftsCount} more ${pluralize(
                "shift",
                potentialShiftsCount
              )} at ${pluralize("facility", potentialFacilityCount, true)}`}
            </Text>
          )}
        {requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES &&
          isOpenShiftsCountLoading && <Skeleton aria-label="Loading Potential Shifts" />}
      </>
    </AccountsRequirementCard>
  );
}
