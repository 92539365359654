import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type ReactElement, useState } from "react";
import { useParams } from "react-router-dom";

import { AccountsRequirementAccordion } from "../Components";
import { CHECKR_REQUIREMENT_ID, REQUIREMENT_STATUS_DATA } from "../constants";
import { DocumentRequirementType, type HcpRequirement } from "../types";
import { CheckrAccountsRequirement } from "./CheckrRequirement";
import { AccountsRequirement } from "./Requirement";

interface AccountsRequirementsProps {
  requirementType: DocumentRequirementType;
  hcpRequirementIds: string[];
  isExpanded: boolean;
  requirements: Record<string, HcpRequirement>;
}

export function AccountsRequirements(props: AccountsRequirementsProps): ReactElement {
  const { requirementType, hcpRequirementIds, isExpanded, requirements } = props;
  const { title, isCollapsible, description } = REQUIREMENT_STATUS_DATA[requirementType];
  const params = useParams<{ hcfId?: string; hcfName?: string }>();

  const [expandedAccordion, setExpandedAccordion] = useState<string[]>(
    isExpanded ? [requirementType] : []
  );

  return (
    <AccountsRequirementAccordion
      isCollapsible={isCollapsible}
      requirementCount={hcpRequirementIds.length}
      groupTitle={
        isDefined(params?.hcfName) &&
        isDefined(params?.hcfId) &&
        requirementType === DocumentRequirementType.REQUIRED_BY_FACILITIES
          ? `Required by this facility`
          : title
      }
      isExpanded={expandedAccordion.includes(requirementType)}
      description={description}
      onAccordionExpanded={() => {
        if (expandedAccordion.includes(requirementType)) {
          setExpandedAccordion(
            expandedAccordion.filter((accordion) => accordion !== requirementType)
          );
        } else {
          setExpandedAccordion([...expandedAccordion, requirementType]);
        }
      }}
    >
      <Stack spacing={2}>
        {hcpRequirementIds
          ?.filter((requirementId: string) => isDefined(requirements[requirementId]))
          .map((requirementId: string) => {
            const requirement = requirements[requirementId];
            if (requirementId === CHECKR_REQUIREMENT_ID) {
              return (
                <CheckrAccountsRequirement
                  key={requirementId}
                  requirement={requirement}
                  requirementType={requirementType}
                />
              );
            }

            return (
              <AccountsRequirement
                key={requirementId}
                requirement={requirement}
                requirementType={requirementType}
              />
            );
          })}
      </Stack>
    </AccountsRequirementAccordion>
  );
}
