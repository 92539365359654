import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { PageWithHeader, useToast } from "@src/appV2/lib";
import { AppBarHeader, BackButtonLink } from "@src/appV2/lib/AppBarHeader";
import { differenceInDays, parseISO } from "date-fns";
import { isArray } from "lodash";
import { type ReactElement } from "react";
import { useLocation, useParams } from "react-router-dom";

import { getFinalHcpRequirementStatus } from "../utils/getFinalHcpRequirementStatus";
import { useGetCheckrApplicationStatus } from "./api/useGetCheckrApplicationStatus";
import { useGetFilteredDocuments } from "./api/useGetFilteredDocuments";
import { useGetRequirementStatus } from "./api/useGetRequirementStatus";
import { RequirementProgressBar } from "./Components/ProgressBar";
import { DocumentPageSkeleton } from "./Components/Skeleton/DocumentPageSkeleton";
import {
  ACCOUNT_ROUTE_V1,
  CHECKR_REQUIREMENT_ID,
  NUMBER_OF_DAYS_TO_SHOW_SOON_TO_EXPIRE_NOTICE,
} from "./constants";
import { useLogRequirementReport } from "./hooks/useLogRequirementReport";
import { AccountsRequirements } from "./Requirements/Requirements";
import { DocumentRequirementType, DocumentStatus, type LocationState } from "./types";

export function DocumentsPage(): ReactElement {
  const { showErrorToast } = useToast();

  const params = useParams<{ hcfId?: string; hcfName?: string }>();
  const { state: locationState } = useLocation<LocationState>();

  const { data: agentProfileData, isLoading: isLoadingAgentProfile } = useAgentProfile();
  const {
    hcpRequirementStatus: hcpRequirementStatusApiResponse,
    totalCountOfMandatoryRequirements = 0,
    countOfPendingOrCompletedMandatoryRequirements = 0,
    isLoading: isLoadingHcpRequirementStatus,
    isRefetching: isRefetchingHcpRequirementStatus,
  } = useGetRequirementStatus(
    {
      workerId: agentProfileData?.userId ?? "",
      ...(isDefined(params?.hcfId) ? { facilitiesId: [params.hcfId] } : {}),
    },
    {
      onError: () => {
        showErrorToast("Something went wrong while loading requirements");
      },
      enabled: isDefined(agentProfileData?.userId),
    }
  );

  const { data: checkrApplicationStatus } = useGetCheckrApplicationStatus({
    enabled: Object.keys(hcpRequirementStatusApiResponse.requirements).includes(
      CHECKR_REQUIREMENT_ID
    ),
  });

  const { data: rejectedDocuments } = useGetFilteredDocuments(
    {
      hcpId: agentProfileData?.userId ?? "",
      filter: {
        fulfilledRequirementId: CHECKR_REQUIREMENT_ID,
        status: DocumentStatus.REJECTED,
        workerUploaded: false,
      },
      limit: 1,
      sort: {
        createdAt: -1,
      },
    },
    {
      enabled: isDefined(checkrApplicationStatus),
    }
  );

  const hcpRequirementStatus = getFinalHcpRequirementStatus({
    hcpRequirementStatus: hcpRequirementStatusApiResponse,
    getFilteredDocumentsApiResponse: rejectedDocuments,
    checkrApplicationStatus,
  });

  useLogRequirementReport({
    hcpRequirementStatus,
    isLoadingHcpRequirementStatus,
    totalCountOfMandatoryRequirements,
  });

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title={
            isDefined(params?.hcfName) && isDefined(params?.hcfId) ? params.hcfName : "Documents"
          }
          leftCta={
            <BackButtonLink
              defaultBackTo={locationState?.returnUrl ?? ACCOUNT_ROUTE_V1}
              alwaysUseDefaultBackTo={Boolean(locationState?.returnUrl)}
            />
          }
        />
      }
    >
      {isLoadingAgentProfile ||
      isLoadingHcpRequirementStatus ||
      isRefetchingHcpRequirementStatus ? (
        <DocumentPageSkeleton />
      ) : (
        <>
          <RequirementProgressBar
            submittedRequirementCount={countOfPendingOrCompletedMandatoryRequirements}
            totalRequirementCount={totalCountOfMandatoryRequirements}
          />
          <Stack
            spacing={3}
            sx={{
              paddingY: 2,
            }}
          >
            {Object.values(DocumentRequirementType)
              .filter((requirementType) => hcpRequirementStatus[requirementType]?.length > 0)
              .map((requirementType) => {
                const isExpanded = hcpRequirementStatus[requirementType].some(
                  (requirementId: string) => {
                    const requirement = hcpRequirementStatus?.requirements[requirementId];
                    if (!requirement) {
                      return false;
                    }

                    return (
                      requirementType === DocumentRequirementType.COMPLETED &&
                      isDefined(requirement.expiry) &&
                      differenceInDays(parseISO(requirement.expiry), new Date()) <=
                        NUMBER_OF_DAYS_TO_SHOW_SOON_TO_EXPIRE_NOTICE
                    );
                  }
                );

                return (
                  <AccountsRequirements
                    key={requirementType}
                    requirementType={requirementType}
                    hcpRequirementIds={
                      isArray(hcpRequirementStatus[requirementType])
                        ? hcpRequirementStatus[requirementType]
                        : []
                    }
                    requirements={hcpRequirementStatus.requirements}
                    isExpanded={isExpanded}
                  />
                );
              })}
          </Stack>
        </>
      )}
    </PageWithHeader>
  );
}
